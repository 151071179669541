import { z } from 'zod'

enum NO_JS {
	true = 'true',
	false = 'false',
}

const schemaDefault = {
	subaction: z.string({ required_error: 'validation.subaction.required' }),
	'no-js': z.nativeEnum(NO_JS, { required_error: 'validation.noJs.required' }),
	redirect: z.string().optional(),
}

export { schemaDefault, NO_JS as SCHEMA_DEFAULT_NO_JS }
