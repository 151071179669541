import { Flex, Link, Text } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxHeart } from 'react-icons/rx'

import { ROOT_I18NEXT } from '~/routes/_layout/root.component'

/* -------------------------------------------------------------------------------------------------
 * SmartMedia
 * -----------------------------------------------------------------------------------------------*/

const SmartMedia: React.FC = () => {
	const { t } = useTranslation(ROOT_I18NEXT.routeRoot)

	return (
		<Link
			color="gray"
			size="1"
			href="https://smart-media.design"
			target="_blank"
		>
			<Flex align="center" gapX="1" my="1">
				<Text size="2" color="gray">
					{t('label.designed')}
				</Text>
				<RxHeart size="1.3em" className="heart" />
				<Text size="2" color="gray">
					{t('label.smartMedia')}
				</Text>
			</Flex>
		</Link>
	)
}
SmartMedia.displayName = 'SmartMedia'

/* -----------------------------------------------------------------------------------------------*/

export { SmartMedia }
