import { type FieldMetadata, getInputProps } from '@conform-to/react'
import {
	TextField as RadixTextField,
	Flex,
	IconButton,
	Tooltip,
} from '@radix-ui/themes'
import cn from 'clsx'
import { type TFunction } from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxEyeClosed, RxEyeOpen } from 'react-icons/rx'
import { mergeRefs } from 'react-merge-refs'
import { FormFieldError } from '~/components/ui/form-field-error.tsx'
import { type LabelProps, Label } from '~/components/ui/label.tsx'

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	uiInput = 'ui-input',
}

/* -------------------------------------------------------------------------------------------------
 * TextField
 * -----------------------------------------------------------------------------------------------*/

type TextFieldElement = React.ElementRef<typeof RadixTextField.Root>
type TextFieldProps = React.PropsWithoutRef<RadixTextField.RootProps> &
	Pick<LabelProps, 'children'> & {
		Icon?: React.FC<{ className?: string }>
		meta: FieldMetadata<string | number>
		t: TFunction<string, undefined>
		value?: string | number
		translationOptions?: {
			[key: string]: string
		}
		validations?: any
	}

const TextField = React.forwardRef<TextFieldElement, TextFieldProps>(
	(props, forwardedRef) => {
		const {
			type = 'text',
			meta,
			children = props.t(`label.${props.name}`),
			Icon,
			t,
			translationOptions,
			validations,
			...rest
		} = props

		const { t: tUI } = useTranslation(I18NEXT.uiInput)
		const [show, setShow] = React.useState(false)
		const ref = React.useRef<HTMLInputElement | null>(null)

		return (
			<Flex direction="column" width="100%">
				<RadixTextField.Root
					ref={mergeRefs([ref, forwardedRef])}
					{...getInputProps(meta, {
						type: type === 'password' && show ? 'text' : type,
					})}
					key={meta.key}
					placeholder=" "
					size="1"
					className="relative"
					{...validations}
					{...rest}
				>
					{!!Icon && (
						<RadixTextField.Slot side="left">
							<Icon />
						</RadixTextField.Slot>
					)}

					<Label
						htmlFor={meta.id}
						size="3"
						className={cn({
							'left-8': !!Icon,
							'left-1.5': !Icon,
						})}
					>
						{children}
					</Label>

					{type === 'password' && (
						<RadixTextField.Slot side="right">
							<Tooltip
								content={
									show ? tUI('button.hidePassword') : tUI('button.showPassword')
								}
							>
								<IconButton
									variant="ghost"
									radius="full"
									type="button"
									mb="1"
									onClick={() => {
										setShow((prev) => !prev)
										ref.current?.focus()
									}}
								>
									{show ? (
										<RxEyeClosed size="1.4em" />
									) : (
										<RxEyeOpen size="1.4em" />
									)}
								</IconButton>
							</Tooltip>
						</RadixTextField.Slot>
					)}
				</RadixTextField.Root>
				<FormFieldError
					error={meta.errors?.[0]}
					t={t}
					translationOptions={translationOptions}
				/>
			</Flex>
		)
	},
)
TextField.displayName = 'TextField'

/* -----------------------------------------------------------------------------------------------*/

export { type TextFieldProps, TextField, I18NEXT }
