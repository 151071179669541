import * as LabelPrimitive from '@radix-ui/react-label'
import { type TextProps, Text } from '@radix-ui/themes'
import * as React from 'react'

/* -------------------------------------------------------------------------------------------------
 * Label
 * -----------------------------------------------------------------------------------------------*/

type LabelElement = React.ElementRef<typeof LabelPrimitive.Root>
type LabelProps = LabelPrimitive.LabelProps & TextProps

const Label = React.forwardRef<LabelElement, LabelProps>(
	(props, forwardedRef) => {
		const { children, ...rest } = props

		return (
			<Text asChild ref={forwardedRef} {...rest}>
				<LabelPrimitive.Root>{children}</LabelPrimitive.Root>
			</Text>
		)
	},
)
Label.displayName = 'Label'

/* -----------------------------------------------------------------------------------------------*/

export { type LabelProps, Label }
