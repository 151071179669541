import { Flex, Theme, ThemePanel } from '@radix-ui/themes'
import { ManifestLink, useSWEffect } from '@remix-pwa/sw'
import { type LinksFunction, type MetaFunction } from '@remix-run/node'
import {
  type ShouldRevalidateFunctionArgs,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import moment from 'moment'
import * as React from 'react'
import 'moment/locale/de.js'
import { useTranslation } from 'react-i18next'
import { type Params } from 'react-router-dom'
import { useChangeLanguage } from 'remix-i18next/react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'

import { type loader } from './root.server'
import { appConfig } from '~/app-config.ts'
import { ClientHintCheck } from '~/components/common/client-hints'
import { AppleMobileWebApp } from '~/components/seo/apple-mobile-web-app.tsx'
import { FavIcon } from '~/components/seo/fav-icon'
import { MsApplication } from '~/components/seo/ms-application.tsx'
import { Toast, ToastContainer } from '~/components/ui/toast'
import i18nConfig from '~/i18n'
import { type Locale } from '~/models/locale'
import { NEWSLETTER_FORM } from '~/routes/($lang).newsletter/newsletter.component'
import { Footer } from '~/routes/_layout/footer.tsx'
import { localesMap } from '~/routes/_layout/i18n'
import { ModalUI } from '~/routes/_layout/modal-ui.tsx'
import { Navbar } from '~/routes/_layout/navbar.tsx'
import { SidebarUI } from '~/routes/_layout/sidebar-ui.tsx'
import globalStylesheetHref from '~/styles/global.css?url'
import { defaultMetaI18next } from '~/utils/client/default-meta-i18next.ts'
import { useIsBot } from '~/utils/hooks/use-is-bot.ts'
import { useNonce } from '~/utils/nonce-provider.ts'

type App = {
	displaySidebar: boolean
	displayModal: boolean
	sidebarView:
    | 'MENU_VIEW'
	modalView:
		| 'MODAL_VIEW'
	options: {
		redirect?: string | null
		preventScrollReset?: boolean
		noJs?: string | null
		successCallback?: () => void | null
		errorCallback?: () => void | null
		data?: {
			[key: string]: string
		}
	}
}

type Context = {
	app: App
	locale: Locale
	setApp: React.Dispatch<React.SetStateAction<App>>
}

type FormEnum<T = { id: string }> = {
	subaction: string
} & (
	| {
			generateId: (props: T) => string
			id?: never
	  }
	| {
			id: string
			generateId?: never
	  }
) &
	(
		| {
				generateAction: (params: Params) => string
				action?: never
		  }
		| {
				action: string
				generateAction?: never
		  }
	) &
	(
		| {
				generateFetcherKey: (props: T) => string
				fetcherKey?: never
		  }
		| {
				fetcherKey: string
				generateFetcherKey?: never
		  }
	) &
	(
		| {
				generateRedirect: (params: Params) => string
				redirect?: never
		  }
		| {
				redirect: string
				generateRedirect?: never
		  }
	)

/* -------------------------------------------------------------------------------------------------
 * App view
 * -----------------------------------------------------------------------------------------------*/

enum appView {
	modalView = 'MODAL_VIEW',
	menuView = 'MENU_VIEW',
}

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	routeRoot = 'route-_root',
	routeNewsletter = 'route-newsletter',
	uiInput = 'ui-input',
	uiI18n = 'ui-i18n',
	uiCloseButton = 'ui-close-button',
	uiFormDefaults = 'ui-form-defaults',
}

/* -------------------------------------------------------------------------------------------------
 * ROUTE_LOADER
 * -----------------------------------------------------------------------------------------------*/

enum ROUTE_LOADER {
	root = 'root',
}

/* -------------------------------------------------------------------------------------------------
 * INPUTS
 * -----------------------------------------------------------------------------------------------*/

enum INPUTS {
	subaction = 'subaction',
	redirect = 'redirect',
	noJs = 'no-js',
}

/* -------------------------------------------------------------------------------------------------
 * SEARCH_PARAMS
 * -----------------------------------------------------------------------------------------------*/

enum SEARCH_PARAMS {
	redirect = 'redirect',
	page = 'page',
	itemsPerPage = 'itemsPerPage',
}

/* -------------------------------------------------------------------------------------------------
 * Handle
 * -----------------------------------------------------------------------------------------------*/

const handle = { i18n: [...Object.values(I18NEXT)] }

/* -------------------------------------------------------------------------------------------------
 * Meta
 * -----------------------------------------------------------------------------------------------*/

const meta: MetaFunction<typeof loader> = ({ data, location }) => {
	return uniqWith(
		data
			? [
					{ title: data.meta.title },
					{
						name: 'description',
						content: data.meta.description,
					},
					// Crawler
					{
						name: 'robots',
						content: 'index,follow',
					},
					{
						name: 'googlebot',
						content: 'index,follow',
					},
					// Open Graph
					{
						property: 'og:title',
						content: appConfig.seo.openGraph.title,
					},
					{
						property: 'og:description',
						content: appConfig.seo.openGraph.description,
					},
					{
						property: 'og:type',
						content: appConfig.seo.openGraph.type,
					},
					{
						property: 'og:site_name',
						content: appConfig.seo.openGraph.site_name,
					},
					{
						property: 'og:url',
						content: appConfig.seo.openGraph.url,
					},
					// Twitter
					{
						name: 'twitter:site',
						content: appConfig.seo.twitter.site,
					},
					{
						name: 'twitter:creator',
						content: appConfig.seo.twitter.creator,
					},
					{
						name: 'twitter:card',
						content: appConfig.seo.twitter.card,
					},
					{
						name: 'twitter:title',
						content: appConfig.seo.twitter.title,
					},
					{
						name: 'twitter:description',
						content: appConfig.seo.twitter.description,
					},
					{
						name: 'twitter:image',
						content: appConfig.seo.twitter.image,
					},
					{
						tagname: 'link',
						rel: 'canonical',
						href: `${appConfig.url_prod}${location.pathname}`,
					},
					// I18next
					...defaultMetaI18next<typeof I18NEXT>({
						I18NEXT,
						locale: data.locale,
					}),
					// Preload current lang flag
					{
						tagname: 'link',
						rel: 'preload',
						href: `/pwa/flags/${localesMap[data.locale].img.filename}`,
						as: 'image/svg+xml',
					},
					// Preload images
				]
			: [],
		(a, b) => isEqual(a, b),
	)
}

/* -------------------------------------------------------------------------------------------------
 * Links
 * -----------------------------------------------------------------------------------------------*/

const links: LinksFunction = () => {
	return [
		// Preload CSS as a resource to avoid render blocking
		{
			rel: 'preload',
			href: globalStylesheetHref,
			as: 'style',
		},
		// These should match the css preloads above to avoid css as render blocking resource
		{
			rel: 'stylesheet',
			href: globalStylesheetHref,
		},
		// Languages as alternate
		{
			rel: 'alternate',
			hrefLang: 'x-default',
			href: `${appConfig.url_prod}/${i18nConfig.defaultLng}`,
		},
		...i18nConfig.supportedLngs.map((lng) => ({
			rel: 'alternate',
			hrefLang: lng,
			href: `${appConfig.url_prod}/${lng}`,
		})),
	].filter(Boolean)
}

/* -------------------------------------------------------------------------------------------------
 * Should revalidate
 * -----------------------------------------------------------------------------------------------*/

const shouldRevalidate = ({
	currentUrl,
	formData,
	nextUrl,
	defaultShouldRevalidate,
}: ShouldRevalidateFunctionArgs) => {
	if (
		formData?.get(INPUTS.subaction) === NEWSLETTER_FORM.subaction ||
		currentUrl.searchParams.get(SEARCH_PARAMS.page) !==
			nextUrl.searchParams.get(SEARCH_PARAMS.page) ||
		(currentUrl.searchParams.has(SEARCH_PARAMS.page) &&
			!nextUrl.searchParams.has(SEARCH_PARAMS.page))
	) {
		return false
	}

	return defaultShouldRevalidate
}

/* -------------------------------------------------------------------------------------------------
 * Layout
 * -----------------------------------------------------------------------------------------------*/

type LayoutProps = {
	children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const { i18n } = useTranslation()
	const {
		locale,
		honeyProps,
		ENV: env,
	} = useRouteLoaderData(ROUTE_LOADER.root) || {
		locale: i18nConfig.defaultLng,
		ENV: {},
	}

	const nonce = useNonce()
	const isBot = useIsBot()

	return (
		<HoneypotProvider {...honeyProps}>
			<Toast.Provider multiple={true}>
					<html lang={locale} dir={i18n.dir()}>
						<head>
							<ClientHintCheck nonce={nonce} />
							<meta charSet="utf-8" />
							<meta
								name="viewport"
								content="width=device-width,initial-scale=1"
							/>
							<meta
								httpEquiv="Content-Security-Policy"
								content="upgrade-insecure-requests"
							/>

							<link
								rel="mask-icon"
								href="/pwa/favicon/safari-pinned-tab.svg"
								color="#ffffff"
							/>
							<link rel="shortcut icon" href="/pwa/favicon/favicon.ico" />
							<link
								rel="apple-touch-icon-precomposed"
								href="/pwa/logo/logo_color_text.png"
							/>
							<meta name="application-name" content={appConfig.seo.title} />
							<MsApplication />
							<AppleMobileWebApp />
							<FavIcon />
							<meta name="theme-color" content="#ffffff" />
							<meta
								name="revisit-after"
								content={appConfig.seo.revisit_after}
							/>
							<meta itemProp="copyrightHolder" content={appConfig.seo.title} />
							<meta
								itemProp="copyright_year"
								content={appConfig.seo.copyright_year}
							/>
							<meta itemProp="isFamilyFriendly" content="true" />
							<meta itemProp="image" content="/pwa/logo/logo_color_text.png" />
							<meta name="mobile-web-app-capable" content="yes" />

							<Meta />
							<ManifestLink />
							<Links />
						</head>
						<body>
							{children}
							<script
								nonce={nonce}
								dangerouslySetInnerHTML={{
									__html: `window.ENV = ${JSON.stringify(env)}`,
								}}
							/>
							<ScrollRestoration nonce={nonce} />
							{!isBot && <Scripts nonce={nonce} />}
						</body>
					</html>
			</Toast.Provider>
		</HoneypotProvider>
	)
}

/* -------------------------------------------------------------------------------------------------
 * App
 * -----------------------------------------------------------------------------------------------*/

const App: React.FC = () => {
	const { locale } = useLoaderData<typeof loader>() || {
		locale: i18nConfig.defaultLng,
	}

	const [app, setApp] = React.useState<App>({
		displaySidebar: false,
		displayModal: false,
		modalView: appView.menuView,
		sidebarView: appView.menuView,
		options: {
			redirect: null,
		},
	})

	moment.locale(
		(() => {
			switch (locale) {
				case 'zh':
					return 'zh-cn'
				default:
					return locale
			}
		})(),
	)

	useSWEffect()

	useChangeLanguage(locale)

	return (
		<Layout>
			<Theme
				appearance="light"
				accentColor="cyan"
				radius="medium"
				scaling="100%"
				panelBackground="solid"
			>
				<Flex
					direction="column"
					position="relative"
					minWidth="100vw"
					minHeight="100vh"
				>
					<Navbar app={app} locale={locale} setApp={setApp} />
					<Flex direction="column" flexGrow="1">
						<Outlet
							context={{
								app,
								locale,
								setApp,
							}}
						/>
					</Flex>
					<Footer app={app} locale={locale} setApp={setApp} />
					<ToastContainer />
				</Flex>
				{process.env.NODE_ENV === 'development' && false && <ThemePanel />}
			</Theme>
		</Layout>
	)
}

/* -----------------------------------------------------------------------------------------------*/

export default withSentry(App)
export {
	shouldRevalidate,
	links,
	meta,
	handle,
	appView,
	I18NEXT as ROOT_I18NEXT,
	ROUTE_LOADER as ROOT_ROUTE_LOADER,
	INPUTS as ROOT_INPUTS,
	SEARCH_PARAMS as ROOT_SEARCH_PARAMS,
}

export type { App, Context, LayoutProps, FormEnum }
