import * as React from 'react'
import { appConfig } from '~/app-config.ts'

/* -------------------------------------------------------------------------------------------------
 * MsApplication
 * -----------------------------------------------------------------------------------------------*/

enum MS_APPLICATION {
	'msapplication-TileColor' = '#ffffff',
	'msapplication-TileImage' = '/pwa/favicon/mstile-144x144.png',
	'msapplication-config' = '/pwa/favicon/browserconfig.xml',
	'msapplication-window' = 'width=1024;height=768',
	'msapplication-starturl' = `${appConfig.url_prod}`,
	'msapplication-navbutton-color' = '#ffffff',
}

const MsApplication: React.FC = () => (
	<>
		{Object.entries(MS_APPLICATION).map(([key, value]) => (
			<meta key={key} name={key} content={value} />
		))}
	</>
)

/* -----------------------------------------------------------------------------------------------*/

export { MsApplication, MS_APPLICATION }
