const appConfig = {
	theme_color: 'hsl(131, 41%, 47%)',
	features: {
		loginAfterRegistration: false,
		loginAfterResetPassword: true,
		wishlist: true,
	},
	url_dev: 'http://localhost:3000',
	url_dev_api: 'https://localhost',
	url_prod: 'https://bsk-bs.de',
	legal: {
		company: 'BSK Bau',
		street: 'Thomas-Mann-Strasse 20',
		country: 'Deutschland',
		state: 'Niedersachsen',
		country_2_digits: 'D',
		postcode: '38239',
		city: 'Salzgitter',
		manager: 'Fredi Pannasch',
		phone: '0049015255296934',
		phone_label: '+49-(0) 152 552 96934',
		email: 'info@bsk-bs.de',
		web: 'https://bsk-bs.de',
		bank: 'Sparkasse Hildesheim/Goslar/Peine',
		iban: 'DE86 2595 0130 0056 9669 52',
		bic: 'NOLADE21HIK',
		tax_id: '51/133/11274',
	},
	seo: {
		title: 'BSK Bau',
		description: 'BSK Bau - Qualität durch Effizienz',
		openGraph: {
			title: 'BSK Bau',
			description: 'BSK Bau - Qualität durch Effizienz',
			type: 'website',
			url: 'https://bsk-bs.de',
			site_name: 'BSK Bau',
			images: [
				{
					url: '/pwa/logo.png',
					width: '800',
					height: '600',
					alt: 'BSK Bau',
				},
			],
		},
		twitter: {
			site: '@BSK Bau',
			creator: '@BSK Bau',
			card: 'summary',
			title: 'BSK Bau',
			description: 'BSK Bau - Qualität durch Effizienz',
			image: 'https://bsk-bs.de/pwa/logo/logo_color_text.png',
		},
		revisit_after: '15 days',
		copyright_year: '2023',
	},
}

export { appConfig }
