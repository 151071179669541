import cn from 'clsx'
import {
  Box,
  Button,
  Container,
  Flex,
  Separator,
  Text,
  Theme,
  Link as RadixLink,
} from '@radix-ui/themes'
import { Link, NavLink } from '@remix-run/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RiPhoneLine } from 'react-icons/ri'
import { RxChatBubble } from 'react-icons/rx'

import { type Locale } from '~/models/locale'
import { type App, ROOT_I18NEXT } from '~/routes/_layout/root.component'
import { Image } from '~/components/ui/image.tsx'
import { menu } from '~/routes/_layout/navbar.tsx'
import { Copyright } from '~/routes/_layout/copyright.tsx'
import { SmartMedia } from '~/routes/_layout/smart-media.tsx'

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/

export const legalMenu = [
  { label: 'legal_notice', path: '/legal-notice' },
  { label: 'privacy_policy', path: '/privacy-policy' },
]

type FooterProps = {
	app: App
	locale: Locale
	setApp: React.Dispatch<React.SetStateAction<App>>
}

const Footer: React.FC<FooterProps> = ({ locale, setApp }) => {
	const { t } = useTranslation(ROOT_I18NEXT.routeRoot)

	return (
    <Theme
      appearance="dark"
      accentColor="cyan"
      radius="medium"
      scaling="100%"
      panelBackground="solid"
    >
      <footer className="border-t border-accent-8 py-8 bg-accent-3">
        <Container px="5">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
            <div className="flex flex-col gap-6">
              <Image alt="Logo" src="/pwa/logo.svg" width={200} height={200}/>
            </div>
            <div className="flex flex-col gap-4">
              <Text size="4" className="border-l-4 border-accent-9 pl-2">{t('general')}</Text>
              <nav className="flex flex-col gap-x-3 gap-y-1">
                {menu.map(({ label, path }) => (
                  <RadixLink
                    asChild
                    key={label + path}
                  >
                    <NavLink
                      end
                      prefetch="intent"
                      to={path}
                      className="hover:text-accent-7"
                    >
                      {t(label)}
                    </NavLink>
                  </RadixLink>
                ))}
              </nav>
            </div>
            <div className="flex flex-col gap-4">
              <Text size="4" className="border-l-4 border-accent-9 pl-2">{t('legal')}</Text>
              <nav className="flex flex-col gap-x-3 gap-y-2">
                {legalMenu.map(({ label, path }) => (
                  <RadixLink
                    asChild
                    key={label + path}
                  >
                    <NavLink
                      key={label + path}
                      end
                      prefetch="intent"
                      to={`/legal${path}`}
                      className="hover:text-accent-7"
                    >
                      {t(label)}
                    </NavLink>
                  </RadixLink>
                ))}
              </nav>
            </div>
            <div className="flex flex-col gap-4">
              <Text size="4" className="border-l-4 border-accent-9 pl-2 whitespace-nowrap overflow-hidden">
                {t('get_free_estimate')}
              </Text>
              <a href="tel:004915255296934"
                 className="flex items-center gap-x-1 font-bold text-lg text-accent-8 hover:underline">
                <RiPhoneLine size="1.2em" />
                <span>+49-(0) 152 552 96934</span>
              </a>
              <a href="tel:00491787563670"
                 className="flex items-center gap-x-1 font-bold text-lg text-accent-8 hover:underline">
                <RiPhoneLine size="1.2em" />
                <span>+49-(0) 178 756 3670</span>
              </a>
              <Button asChild variant="outline" size="3">
                <Link to="/contact" prefetch="viewport" className="flex items-center gap-x-2">
                  <RxChatBubble size="1.2em" />
                  {t('request_contact')}
                </Link>
              </Button>
            </div>
          </div>
          <Separator orientation="horizontal" size="4" className="!bg-gray-11 my-8"/>
          <Flex
            direction={{ initial: 'column', sm: 'row' }}
            justify="between"
            gap="1"
            py="5"
            align={{ initial: 'center', sm: 'end' }}
          >
            <Copyright />
            <SmartMedia />
          </Flex>
        </Container>
      </footer>
    </Theme>
  )
}
Footer.displayName = 'Footer'

/* -----------------------------------------------------------------------------------------------*/

export { Footer }
