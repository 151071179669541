import { type TextProps, Text } from '@radix-ui/themes'
import { type TFunction } from 'i18next'
import * as React from 'react'

/* -------------------------------------------------------------------------------------------------
 * FormFieldError
 * -----------------------------------------------------------------------------------------------*/

type FormFieldErrorElement = React.ElementRef<typeof Text>
type FormFieldErrorProps = React.PropsWithoutRef<TextProps> & {
	error?: string
	t: TFunction<string, undefined>
	translationOptions?: {
		[key: string]: string
	}
}

const FormFieldError = React.forwardRef<
	FormFieldErrorElement,
	FormFieldErrorProps
>((props, forwardedRef) => {
	const { error, t, translationOptions, ...rest } = props

	return error ? (
		<Text
			ref={forwardedRef}
			size="1"
			color="red"
			className="ml-1 mt-1"
			{...rest}
		>
			{t(error, translationOptions)}
		</Text>
	) : null
})
FormFieldError.displayName = 'FormFieldError'

/* -----------------------------------------------------------------------------------------------*/

export { FormFieldError }
