import { getHintUtils } from '@epic-web/client-hints'
import {
	clientHint as colorSchemeHint,
	subscribeToSchemeChange,
} from '@epic-web/client-hints/color-scheme'
import { clientHint as timeZoneHint } from '@epic-web/client-hints/time-zone'
import { useRevalidator } from '@remix-run/react'
import * as React from 'react'
import { useRequestInfo } from '~/utils/request-info.ts'

const hintsUtils = getHintUtils({
	theme: colorSchemeHint,
	timeZone: timeZoneHint,
	// add other hints here
})

const { getHints } = hintsUtils

/**
 * @returns an object with the client hints and their values
 */
function useHints() {
	const requestInfo = useRequestInfo()
	return requestInfo.hints
}

/* -------------------------------------------------------------------------------------------------
 * CheckoutLink
 * -----------------------------------------------------------------------------------------------*/

function ClientHintCheck({ nonce }: { nonce: string }) {
	const { revalidate } = useRevalidator()
	React.useEffect(
		() => subscribeToSchemeChange(() => revalidate()),
		[revalidate],
	)

	return (
		<script
			nonce={nonce}
			dangerouslySetInnerHTML={{
				__html: hintsUtils.getClientHintCheckScript(),
			}}
		/>
	)
}

/* -----------------------------------------------------------------------------------------------*/

export { getHints, useHints, ClientHintCheck }
