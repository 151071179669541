import { type SpinnerProps, Spinner } from '@radix-ui/themes'
import { type Fetcher, type Navigation } from '@remix-run/react'
import * as React from 'react'

type SubmissionButtonProps = Pick<SpinnerProps, 'children'> &
	(
		| {
				fetcher: Fetcher
				navigation?: never
				same?: never
		  }
		| {
				fetcher?: never
				navigation: Navigation
				same: boolean
		  }
	)

const SubmissionButton: React.FC<SubmissionButtonProps> = ({
	children,
	fetcher,
	navigation,
	same,
}) => {
	return (
		<Spinner
			loading={
				fetcher ? fetcher.state !== 'idle' : navigation.state !== 'idle' && same
			}
		>
			{children}
		</Spinner>
	)
}

export { SubmissionButton }
