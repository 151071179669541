import { type MetaArgs, type MetaFunction } from '@remix-run/node'
import {
	type ShouldRevalidateFunctionArgs,
	useOutletContext,
} from '@remix-run/react'
import * as React from 'react'
import { z } from 'zod'

import { NewsletterForm } from '~/routes/($lang).newsletter/newsletter-form.tsx'
import { type loader } from '~/routes/($lang).newsletter/newsletter.server'
import {
	type Context,
	type FormEnum,
	ROOT_INPUTS,
} from '~/routes/_layout/root.component'
import { metaDefault } from '~/utils/client/meta-default.ts'
import { schemaDefault } from '~/utils/form-defaults.ts'

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	routeNewsletter = 'route-newsletter',
}

/* -------------------------------------------------------------------------------------------------
 * ROUTE_LOADER
 * -----------------------------------------------------------------------------------------------*/

enum ROUTE_LOADER {
	current = 'routes/($lang).newsletter',
}

/* -------------------------------------------------------------------------------------------------
 * SEARCH_PARAMS
 * -----------------------------------------------------------------------------------------------*/

enum SEARCH_PARAMS {
	newsletterSubscribed = 'newsletter-subscribed',
	email = 'newsletterEmail',
}

/* -------------------------------------------------------------------------------------------------
 * INPUTS
 * -----------------------------------------------------------------------------------------------*/

enum INPUTS {
	email = 'newsletterEmail',
}

/* -------------------------------------------------------------------------------------------------
 * FORM
 * -----------------------------------------------------------------------------------------------*/

const FORM: FormEnum = {
	id: 'subscribe-newsletter-form',
	action: '/newsletter',
	fetcherKey: 'subscribe-newsletter',
	subaction: 'subscribe-newsletter',
	redirect: '/newsletter',
} as const

const schema = z.object({
	...schemaDefault,
	...{
		[INPUTS.email]: z
			.string({ required_error: 'validation.email.required' })
			.email('validation.email.email')
			.max(254, 'validation.email.max'),
	},
})

/* -------------------------------------------------------------------------------------------------
 * Meta
 * -----------------------------------------------------------------------------------------------*/

const meta: MetaFunction<typeof loader> = (props: MetaArgs) =>
	metaDefault({ ...props, ROUTE_LOADER, I18NEXT: {} })

/* -------------------------------------------------------------------------------------------------
 * Should revalidate
 * -----------------------------------------------------------------------------------------------*/

const shouldRevalidate = ({
	formData,
	defaultShouldRevalidate,
}: ShouldRevalidateFunctionArgs) => {
	if (formData?.get(ROOT_INPUTS.subaction) === FORM.subaction) {
		return false
	}

	return defaultShouldRevalidate
}

/* -------------------------------------------------------------------------------------------------
 * Component
 * -----------------------------------------------------------------------------------------------*/

const Component: React.FC = () => {
	const { locale, setApp } = useOutletContext<Context>()

	return <NewsletterForm locale={locale} setApp={setApp} />
}
Component.displayName = 'Component'

/* -----------------------------------------------------------------------------------------------*/

export {
	Component,
	shouldRevalidate,
	meta,
	I18NEXT as NEWSLETTER_I18NEXT,
	ROUTE_LOADER as NEWSLETTER_ROUTE_LOADER,
	SEARCH_PARAMS as NEWSLETTER_SEARCH_PARAMS,
	INPUTS as NEWSLETTER_INPUTS,
	FORM as NEWSLETTER_FORM,
	schema as newsletterSchema,
}
