import * as React from 'react'

/* -------------------------------------------------------------------------------------------------
 * FavIcon
 * -----------------------------------------------------------------------------------------------*/

enum APPLE_TOUCH_ICONS {
	'57x57',
	'60x60',
	'72x72',
	'76x76',
	'114x114',
	'120x120',
	'144x144',
	'152x152',
	'180x180',
}

enum FAVICONS {
	'16x16',
	'32x32',
	'192x192',
	'194x194',
}

const FavIcon: React.FC = () => {
	return (
		<>
			{Object.values(APPLE_TOUCH_ICONS).map((item) => (
				<link
					key={item}
					rel="apple-touch-icon"
					sizes={item as string}
					href={`/pwa/favicon/apple-touch-icon-${item}.png`}
				/>
			))}
			{Object.values(FAVICONS).map((item) => (
				<link
					key={item}
					rel="apple-touch-icon"
					sizes={item as string}
					href={`/pwa/favicon/favicon-${item}.png`}
				/>
			))}
		</>
	)
}

/* -----------------------------------------------------------------------------------------------*/

export {
	FavIcon,
	APPLE_TOUCH_ICONS as FAV_ICON_APPLE_TOUCH_ICONS,
	FAVICONS as FAV_ICON_FAVICONS,
}
