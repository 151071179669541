import { FormProvider, getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import {
	Box,
	Button,
	DropdownMenu,
	Flex,
	IconButton,
	VisuallyHidden,
} from '@radix-ui/themes'
import { Form, useLocation } from '@remix-run/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxChevronUp } from 'react-icons/rx'

import { z } from 'zod'
import { FormDefaults } from '~/components/common/form-defaults.tsx'
import { Icon } from '~/components/ui/icon.tsx'
import { Image } from '~/components/ui/image'
import { type Locale } from '~/models/locale'
import { ROOT_INPUTS } from '~/routes/_layout/root.component'
import { schemaDefault } from '~/utils/form-defaults.ts'

type LocaleData = {
	name: string
	img: {
		filename: string
		alt: string
	}
}

type I18nProps = {
	locale: Locale
}

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	uiI18n = 'ui-i18n',
}

/* -------------------------------------------------------------------------------------------------
 * INPUTS
 * -----------------------------------------------------------------------------------------------*/

enum INPUTS {
	locale = 'locale',
}

/* -------------------------------------------------------------------------------------------------
 * Form
 * -----------------------------------------------------------------------------------------------*/

enum FORM {
	id = 'i18n-form',
	action = '/actions/set-locale',
	fetcherKey = 'i18n',
	subaction = 'i18n',
}

const schema = z.object({
	...schemaDefault,
})

/* -------------------------------------------------------------------------------------------------
 * TEST_IDS
 * -----------------------------------------------------------------------------------------------*/

enum TEST_IDS {
	popoverTrigger = 'i18n-selector',
}

const localesMap: Record<string, LocaleData> = {
	de: {
		name: 'Deutsch',
		img: {
			filename: 'de.svg',
			alt: 'German Flag',
		},
	},
	en: {
		name: 'English',
		img: {
			filename: 'us.svg',
			alt: 'US Flag',
		},
	},
}

/* -------------------------------------------------------------------------------------------------
 * I18n
 * -----------------------------------------------------------------------------------------------*/

const I18n: React.FC<I18nProps> = ({ locale }) => {
	const location = useLocation()
	const { t } = useTranslation(I18NEXT.uiI18n)

	const [form, fields] = useForm({
		defaultValue: {
			[ROOT_INPUTS.subaction]: FORM.subaction,
			[ROOT_INPUTS.noJs]: 'true',
			[ROOT_INPUTS.redirect]: `${location.pathname}${location.search}`,
		},
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: schema })
		},
		shouldValidate: 'onInput',
	})

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger className="group">
				<Flex asChild gapX="1">
					<IconButton
						data-testid={TEST_IDS.popoverTrigger}
						variant="ghost"
						size="3"
					>
						<VisuallyHidden>{t('button.trigger')}</VisuallyHidden>
						<Image
							api={false}
							src={`/pwa/flags/${localesMap[locale].img.filename}`}
							alt={localesMap[locale].img.filename}
							width={23}
							height={23}
						/>
						<Icon>
							<RxChevronUp
								size="1em"
								className="transition-all duration-300 ease-in-out group-radix-state-open:rotate-180"
							/>
						</Icon>
					</IconButton>
				</Flex>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content side="top">
				<FormProvider context={form.context}>
					<Form
						method="post"
						action={FORM.action}
						navigate={false}
						fetcherKey={FORM.fetcherKey}
						{...getFormProps(form)}
						preventScrollReset
					>
						<FormDefaults form={form} fields={fields} />
						<ul>
							{Object.entries(localesMap).map(([key, item]) => (
								<DropdownMenu.Item key={key} asChild>
									<li>
										<Box asChild py="3">
											<Button
												variant="ghost"
												name={INPUTS.locale}
												value={key}
												disabled={locale === key}
											>
												<Image
													api={false}
													src={`/pwa/flags/${item.img.filename}`}
													alt={item.img.filename}
													width={23}
													height={23}
													loading="lazy"
												/>
												{item.name}
											</Button>
										</Box>
									</li>
								</DropdownMenu.Item>
							))}
						</ul>
					</Form>
				</FormProvider>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	)
}
I18n.displayName = 'I18n'

/* -----------------------------------------------------------------------------------------------*/

export {
	I18n,
	localesMap,
	FORM as I18N_FORM,
	INPUTS as I18N_INPUTS,
	I18NEXT as I18N_I18NEXT,
	TEST_IDS as I18N_TEST_IDS,
	type LocaleData,
	schema as I18nSchema,
}
