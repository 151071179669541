import * as React from 'react'
import { v4 } from 'uuid'

type Type = 'green' | 'blue' | 'red' | 'yellow'

type Data = {
	message: string
	description: string
	type: Type
}

type InnerData = Data & {
	id: string
}

type UseToastProps = {
	toast?: Data
	multiple?: boolean
	max?: number
}

function useToast(props: UseToastProps = {}) {
	const { multiple = false, max } = props

	const [toasts, setToasts] = React.useState<InnerData[]>([])

	const addToast = (toast: Data) => {
		setToasts((prev) => [
			...(multiple
				? prev.slice(Math.max(prev.length - (max || prev.length), 0))
				: []),
			{
				...toast,
				id: v4(),
				time: Date.now(),
			},
		])
	}

	const removeToast = ({ id }: Pick<InnerData, 'id'>) => {
		setToasts((prev) => [...prev.filter((toast) => toast.id !== id)])
	}

	return {
		toasts,
		setToasts,
		addToast,
		removeToast,
	}
}

export {
	type Type as ToastType,
	type Data as ToastData,
	type InnerData as ToastInnerData,
	type UseToastProps,
	useToast,
}
