import cn from 'clsx'
import { Link, NavLink, useLocation, useNavigation } from '@remix-run/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxChatBubble, RxEnvelopeClosed } from 'react-icons/rx'
import { RiPhoneLine } from 'react-icons/ri'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

import { type Locale } from '~/models/locale'
import {
	type App,
	appView,
	ROOT_I18NEXT,
} from '~/routes/_layout/root.component'
import { Button, Container, ScrollArea, Separator, Text } from '@radix-ui/themes'
import { Image } from '~/components/ui/image.tsx'
import { calc } from '@csstools/css-calc'

const menu = [
  { label: 'services', path: '/#services' },
  { label: 'jobs', path: '/#jobs' },
  { label: 'about_us', path: '/#about-us' },
  { label: 'tips_and_tricks', path: '/#tips-and-tricks' },
]

/* -------------------------------------------------------------------------------------------------
 * Navbar
 * -----------------------------------------------------------------------------------------------*/

type NavbarProps = {
  app: App
	locale: Locale
	setApp: React.Dispatch<React.SetStateAction<App>>
}

const Navbar: React.FC<NavbarProps> = ({ locale, setApp }) => {
	const { pathname, search } = useLocation()
	const { t } = useTranslation(ROOT_I18NEXT.routeRoot)
	const { location } = useNavigation()




	return (
    <>
      <div className="header-bg sticky top-0 z-10">
        <Container>
          <div className="grid md:grid-cols-8 justify-center gap-y-1 py-1 md:py-0">
            <div className="md:col-span-4 lg:col-span-3 py-2 md:pr-6 text-accent2 flex justify-center md:justify-start items-center gap-x-2">
              <RiPhoneLine size="1.4em" className="text-gray-2" />
              <Text size="2" className="text-gray-5">{t('client_services')}</Text>
              <a href="tel:0049015255296934" className="text-sm italic text-gray-2">+49-(0) 152 552 96934</a>
            </div>
            <div className="md:col-span-4 lg:col-span-5 py-2 flex justify-center md:justify-end text-accent3 flex items-center gap-x-4">
              <div className="flex items-center gap-x-2">
                <RxEnvelopeClosed size="1.4em" className="text-gray-2" />
                <Text size="2" className="text-gray-5">{t('email')}</Text>
                <a href="mailto:info@bsk-bs.de" className="text-sm italic text-gray-2">info@bsk-bs.de</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-white py-2">
        <Container>
          <div className="flex flex-col lg:flex-row items-center justify-between py-5 gap-y-4">
            <Link to="/">
              <Image alt="Logo" src="/pwa/logo.svg" width={200} height={200} />
            </Link>
            <div className="flex flex-col md:flex-row items-center gap-x-5 gap-y-4">
              <ScrollArea scrollbars="horizontal" className="app-NavScrollArea">
                <nav className="flex items-center gap-x-5 px-2">
                  {menu.map(({ label, path }) => (
                    <NavLink
                      key={label + path}
                      end
                      prefetch="intent"
                      to={path}
                      className="!text-accent-9 hover:!text-accent-7 uppercase font-bold whitespace-nowrap"
                    >
                      {t(label)}
                    </NavLink>
                  ))}
                </nav>
              </ScrollArea>
              <Separator orientation="vertical" size="2" color="gray" className="hidden md:block w-1" />
              <Button variant="outline" size="3">
                <Link to="/contact" prefetch="viewport" className="flex items-center gap-x-2 whitespace-nowrap">
                  <RxChatBubble className="text-xl" />
                  {t('request_contact')}
                </Link>
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
	)
}
Navbar.displayName = 'Navbar'

/* -----------------------------------------------------------------------------------------------*/

export { Navbar, menu }
