import { FormProvider, getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { Box, Button, Callout, Flex } from '@radix-ui/themes'
import {
	useFetcher,
	useLocation,
	useSearchParams,
} from '@remix-run/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxInfoCircled } from 'react-icons/rx'
import { useHydrated } from 'remix-utils/use-hydrated'

import { FormDefaults } from '~/components/common/form-defaults.tsx'
import { SubmissionButton } from '~/components/common/submission-button.tsx'
import { TextField } from '~/components/ui/text-field.tsx'
import { type Locale } from '~/models/locale.ts'
import {
	NEWSLETTER_I18NEXT,
	NEWSLETTER_FORM,
	NEWSLETTER_INPUTS,
	newsletterSchema,
	NEWSLETTER_SEARCH_PARAMS,
} from '~/routes/($lang).newsletter/newsletter.component.tsx'
import { type App, ROOT_INPUTS } from '~/routes/_layout/root.component.tsx'

/* -------------------------------------------------------------------------------------------------
 * Newsletter
 * -----------------------------------------------------------------------------------------------*/

type NewsletterProps = {
	locale: Locale
	setApp: React.Dispatch<React.SetStateAction<App>>
}

const NewsletterForm: React.FC<NewsletterProps> = ({ locale }) => {
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const { t } = useTranslation(NEWSLETTER_I18NEXT.routeNewsletter)
	const isHydrated = useHydrated()
	const fetcher = useFetcher({ key: NEWSLETTER_FORM.fetcherKey })
	const [form, fields] = useForm({
		defaultValue: {
			[ROOT_INPUTS.subaction]: NEWSLETTER_FORM.subaction,
			[ROOT_INPUTS.noJs]: String(!isHydrated),
		},
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: newsletterSchema })
		},
		shouldValidate: 'onBlur',
	})
	const isSubscribed = React.useMemo(
		() => fetcher.data?.status === 201,
		[fetcher.data?.status],
	)

	/*
  useNotify({
    ...fetcher,
    setApp,
    translations: {
      success: {
        title: t("notify.newsletterSubscription.success.title"),
        description: (
          <StringToComponent
            text={t("notify.newsletterSubscription.success.description-$val")}
          >
            <Strong>
              {fetcher.formData?.get(NEWSLETTER_INPUTS.email) as string}
            </Strong>
          </StringToComponent>
        ),
      },
      error: {
        title: t("notify.newsletterSubscription.error.title"),
        description: (
          <StringToComponent
            text={t("notify.newsletterSubscription.error.description-$val")}
          >
            <Strong>
              {fetcher.formData?.get(NEWSLETTER_INPUTS.email) as string}
            </Strong>
          </StringToComponent>
        ),
      },
    },
  });
   */

	React.useEffect(() => {
		if (isSubscribed) {
			form.reset()
		}
	}, [fetcher.data?.status, isSubscribed, form])

	return (
		<FormProvider context={form.context}>
			<Flex asChild direction="column" gapY="4">
				<fetcher.Form
					method="post"
					action={`/${locale}${NEWSLETTER_FORM.action}${location.search}`}
					{...getFormProps(form)}
					preventScrollReset
				>
					<FormDefaults form={form} fields={fields} />
					{(searchParams.get(NEWSLETTER_SEARCH_PARAMS.newsletterSubscribed) ===
						'on' ||
						isSubscribed) && (
						<Box mb="4">
							<Callout.Root>
								<Callout.Icon>
									<RxInfoCircled />
								</Callout.Icon>
								<Callout.Text>{t('alert.newsletterSubscribed')}</Callout.Text>
							</Callout.Root>
						</Box>
					)}
					<TextField
						type="email"
						meta={fields[NEWSLETTER_INPUTS.email]}
						t={t}
						disabled={isSubscribed}
					>
						{t('label.email')}
					</TextField>
					<Button
						size="4"
						variant="surface"
						type="submit"
						disabled={isSubscribed}
					>
						<SubmissionButton fetcher={fetcher}>
							{t('button.newsletterSubmit')}
						</SubmissionButton>
					</Button>
				</fetcher.Form>
			</Flex>
		</FormProvider>
	)
}
NewsletterForm.displayName = 'Newsletter'

/* -----------------------------------------------------------------------------------------------*/

export { NewsletterForm }
