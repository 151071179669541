import * as React from 'react'

type UseProgressProps = {
	duration: number
}

function useProgress({ duration }: UseProgressProps) {
	const [value, setValue] = React.useState(0)
	const eventDateRef = React.useRef(Date.now())
	const intervalRef = React.useRef<NodeJS.Timer | undefined>()

	React.useEffect(() => {
		const one = duration! / 100
		intervalRef.current = setInterval(() => {
			const percent = (Date.now() - eventDateRef.current) / one
			if (percent <= 100) {
				setValue(percent)
			} else {
				// @ts-ignore
				clearInterval(intervalRef.current)
			}
		}, 50)
	}, [duration, intervalRef, eventDateRef, setValue])

	return {
		value,
	}
}

export { useProgress }
