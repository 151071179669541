import * as React from 'react'

/* -------------------------------------------------------------------------------------------------
 * AppleMobileWebApp
 * -----------------------------------------------------------------------------------------------*/

enum APPLE_MOBILE_WEB_APP {
	'apple-mobile-web-app-title' = 'BSK Bau',
	'apple-mobile-web-app-capable' = 'yes',
	'apple-mobile-web-app-status-bar-style' = 'default',
}

const AppleMobileWebApp: React.FC = () => (
	<>
		{Object.entries(APPLE_MOBILE_WEB_APP).map(([key, value]) => (
			<meta key={key} name={key} content={value} />
		))}
	</>
)

/* -----------------------------------------------------------------------------------------------*/

export { AppleMobileWebApp, APPLE_MOBILE_WEB_APP }
