import {
	type FormMetadata,
	type FieldMetadata,
	getInputProps,
} from '@conform-to/react'
import { Box, Callout, Flex } from '@radix-ui/themes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RxInfoCircled } from 'react-icons/rx'

/* -------------------------------------------------------------------------------------------------
 * I18NEXT
 * -----------------------------------------------------------------------------------------------*/

enum I18NEXT {
	uiFormDefaults = 'ui-form-defaults',
}

/* -------------------------------------------------------------------------------------------------
 * Fields
 * -----------------------------------------------------------------------------------------------*/

enum FIELDS {
	subaction = 'subaction',
	noJs = 'no-js',
	redirect = 'redirect',
}

/* -------------------------------------------------------------------------------------------------
 * FormDefaults
 * -----------------------------------------------------------------------------------------------*/

type FormDefaultsProps = {
	form: FormMetadata<{
		subaction: string
		'no-js': string
		redirect?: string
	}>
	fields: Required<{
		subaction: FieldMetadata<string>
		'no-js': FieldMetadata<string>
		redirect?: FieldMetadata<string> | undefined
	}>
}

const FormDefaults: React.FC<FormDefaultsProps> = ({ form, fields }) => {
	return (
		<Flex direction="column">
			{Object.values(FIELDS).map((value) =>
				value in (form.initialValue || {}) ? (
					<FormDefaultsHiddenField key={value} meta={fields[value]} />
				) : null,
			)}
		</Flex>
	)
}
FormDefaults.displayName = 'FormDefaults'

/* -------------------------------------------------------------------------------------------------
 * FormDefaultsError
 * -----------------------------------------------------------------------------------------------*/

type FormDefaultsErrorProps = {
	error?: string
}

const FormDefaultsError: React.FC<FormDefaultsErrorProps> = ({ error }) => {
	const { t } = useTranslation(I18NEXT.uiFormDefaults)

	return error ? (
		<Callout.Root color="red" my="2">
			<Callout.Icon>
				<RxInfoCircled />
			</Callout.Icon>
			<Callout.Text>{t(error)}</Callout.Text>
		</Callout.Root>
	) : null
}
FormDefaultsError.displayName = 'FormDefaultsError'

/* -------------------------------------------------------------------------------------------------
 * FormDefaultsHiddenField
 * -----------------------------------------------------------------------------------------------*/

type FormDefaultsHiddenFieldProps = {
	meta: FieldMetadata<string | number>
  value?: string | number | undefined
  disabled?: boolean
}

const FormDefaultsHiddenField: React.FC<FormDefaultsHiddenFieldProps> = ({
	meta,
  ...rest
}) => {
	return (
		<Box>
			<input
        {...getInputProps(meta, { type: 'hidden' })}
        key={meta.key}
        {...rest}
      />
			<FormDefaultsError error={meta.errors?.[0]} />
		</Box>
	)
}
FormDefaultsHiddenField.displayName = 'FormDefaultsHiddenField'

/* -----------------------------------------------------------------------------------------------*/

export {
	type FormDefaultsProps,
	FormDefaults,
	type FormDefaultsErrorProps,
	FormDefaultsError,
	type FormDefaultsHiddenFieldProps,
	FormDefaultsHiddenField,
	I18NEXT as FORM_DEFAULTS_I18NEXT,
}
