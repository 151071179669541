import { Flex, Text } from '@radix-ui/themes'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PiCopyrightLight } from 'react-icons/pi'

import { ROOT_I18NEXT } from '~/routes/_layout/root.component'

/* -------------------------------------------------------------------------------------------------
 * Copyright
 * -----------------------------------------------------------------------------------------------*/

const Copyright: React.FC = () => {
	const { t } = useTranslation(ROOT_I18NEXT.routeRoot)

	return (
    <Flex
      asChild
      direction={{ initial: 'column', xs: 'row' }}
      gap="1"
      my="1"
      align="center"
    >
      <Text size="2" color="gray">
        <Flex align="center" gapX="1">
          <Text as="span" id="copyright">
            {t('label.copyright')}
          </Text>
          <Text>
            <PiCopyrightLight aria-labelledby="copyright" />
          </Text>
          <Text as="span">{moment().format('YYYY')}</Text>
        </Flex>
        <Flex align="center" gapX="1">
          <Text as="span">
            {t('label.brand')}.
          </Text>
          <Text as="span">{t('label.allRightsReserved')}.</Text>
        </Flex>
      </Text>
    </Flex>
	)
}
Copyright.displayName = 'Copyright'

/* -----------------------------------------------------------------------------------------------*/

export { Copyright }
