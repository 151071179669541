import * as ToastPrimitive from '@radix-ui/react-toast'
import { Box, Progress } from '@radix-ui/themes'
import cn from 'clsx'
import * as React from 'react'

import { useProgress } from '~/utils/hooks/useProgress.ts'
import {
	type ToastData,
	type ToastInnerData,
	type ToastType,
	type UseToastProps,
	useToast,
} from '~/utils/hooks/useToast.ts'

/* -------------------------------------------------------------------------------------------------
 * ToastProvider
 * -----------------------------------------------------------------------------------------------*/

type ToastProviderProps = ToastPrimitive.ToastProviderProps & UseToastProps

type ToastContextType = {
	toasts: ToastInnerData[]
	setToasts: React.Dispatch<React.SetStateAction<ToastInnerData[]>>
	addToast: (toast: ToastData) => void
	removeToast: ({ id }: Pick<ToastInnerData, 'id'>) => void
}

const ToastContext = React.createContext<ToastContextType | null>(null)

const ToastProvider: React.FC<ToastProviderProps> = (props) => {
	const { multiple, max, ...rest } = props

	const context = useToast({ multiple, max })

	return (
		<ToastContext.Provider value={context}>
			<ToastPrimitive.Provider {...rest} />
		</ToastContext.Provider>
	)
}
ToastProvider.displayName = 'ToastProvider'

/* -------------------------------------------------------------------------------------------------
 * ToastViewport
 * -----------------------------------------------------------------------------------------------*/

type ToastViewportElement = React.ElementRef<typeof ToastPrimitive.Viewport>
type ToastViewportProps = ToastPrimitive.ToastViewportProps

const ToastViewport = React.forwardRef<
	ToastViewportElement,
	ToastViewportProps
>((props, forwardedRef) => {
	const { className, ...rest } = props

	return (
		<ToastPrimitive.Viewport
			{...rest}
			ref={forwardedRef}
			className={cn(
				'max-w-screen fixed bottom-0 right-0 z-50 m-0 flex w-[390px] list-none flex-col gap-5 p-10 outline-none',
				className,
			)}
		/>
	)
})
ToastViewport.displayName = 'ToastViewport'

/* -------------------------------------------------------------------------------------------------
 * ToastRoot
 * -----------------------------------------------------------------------------------------------*/

type ToastRootElement = React.ElementRef<typeof ToastPrimitive.Root>
type ToastRootProps = ToastPrimitive.ToastProviderProps & {
	type: ToastType
	id: string
	message: string
	description?: string
}

const ToastRoot = React.forwardRef<ToastRootElement, ToastRootProps>(
	(props, forwardedRef) => {
		const {
			children,
			type,
			duration = ['info', 'success'].includes(type) ? 2000 : 8000,
			id,
			message,
			description = undefined,
			...rest
		} = props

		const { removeToast } = React.useContext(ToastContext)!

		const { value } = useProgress({ duration: duration! })

		return (
			<ToastPrimitive.Root
				{...rest}
				ref={forwardedRef}
				className={cn(
					'flex flex-col gap-y-2 rounded-xl border-2 bg-gray-2 p-6 shadow',
					{
						'border-accent-10': type === 'green',
						'border-error-10': type === 'blue',
					},
				)}
				duration={duration}
				onOpenChange={(open) => {
					if (!open) {
						removeToast({ id })
					}
				}}
			>
				<>
					<ToastPrimitive.Title className="text-base font-medium text-gray-10">
						{message}
					</ToastPrimitive.Title>
					<ToastPrimitive.Description className="m-0 text-sm leading-5 text-gray-8">
						{description}
					</ToastPrimitive.Description>
					<Progress value={value} color={type} />
				</>
			</ToastPrimitive.Root>
		)
	},
)
ToastRoot.displayName = 'ToastRoot'

/* -------------------------------------------------------------------------------------------------
 * ToastContainer
 * -----------------------------------------------------------------------------------------------*/

type ToastContainerElement = React.ElementRef<'div'>

const ToastContainer = React.forwardRef<ToastContainerElement>(
	(_, forwardedRef) => {
		const { toasts } = React.useContext(ToastContext)!

		return (
			<Box ref={forwardedRef}>
				{toasts.map((toast) => (
					<ToastRoot key={toast.id} {...toast} />
				))}
			</Box>
		)
	},
)
ToastContainer.displayName = 'ToastContainer'

/* -----------------------------------------------------------------------------------------------*/

const Toast = {
	Provider: ToastProvider,
	Viewport: ToastViewport,
	Container: ToastContainer,
}

export { Toast, ToastContext, ToastProvider, ToastViewport, ToastContainer }
